@import 'styles/vars';

.closeBtn {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  top: 11px;
  left: 11px;
  min-height: 45px;
  min-width: 45px;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-bottom-left-radius: 8px;
  background-color: transparent;
  cursor: pointer;
}

.closeBtn svg {
  @include Animation(fill);

  &:focus,
  &:hover {
    fill: var(--color-primary);
  }
}
