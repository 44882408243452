@import "styles/vars";

.cookieBar {
  z-index: 100;
  position: fixed;
  left: 50%;
  bottom: 0;
  width: 100%;
  padding: 12px;

  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  text-align: center;

  background: var(--color-grey);

  transform: translateX(-50%);

  @media screen and (min-width: 1280px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2.5rem;
    padding: 16px;
  }
}

.cookieText {
  color: var(--color-black);
  font-size: 1.1rem;

  @media screen and (max-width: 1279px) {
    margin-bottom: 16px;
    font-size: 1rem;
  }
}