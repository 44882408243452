@import 'styles/vars';

.product {
  @media screen and (min-width: 1280px) {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
}

.left {
  @media screen and (min-width: 1280px) {
    flex: 1;
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }
}

.images {
  height: fit-content;
  flex: 1;
}

.images img {
  margin-bottom: 10px;
  cursor: pointer;
}

.mainImg {
  @media screen and (max-width: 1279px) {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
  }

  @media screen and (min-width: 1280px) {
    display: flex;
    flex: 5;
    justify-content: center;
  }
}

.mainImg img {
  max-height: 690px;
  max-width: max-content;
  object-fit: contain;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 1279px) {
    text-align: center;
  }
}

.price {
  font-size: 30px;
  color: #000;
  // color: var(--color-primary);
}

.optionTitle {
  margin-bottom: 6px;
  color: var(--color-secondary);
  font-weight: 300;
}

.optionTitle span {
  color: var(--color-white);
  font-weight: 400;
}

.option > div:not(:last-child) {
  margin-bottom: 16px;
}

.radioInput {
  visibility: hidden;

  &:checked + span {
    border: 2px solid var(--color-white);
  }
}

.radioColor {
  position: relative;
  left: -8px;
  top: 6px;
  width: 28px;
  height: 28px;
  display: inline-block;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
}

.size__value {
  position: relative;
  left: -8px;
  top: 6px;
  width: 42px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  background-color: var(--color-black);
  color: var(--color-white);
  cursor: pointer;
}

.quantity {
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 719px) {
    justify-content: center;
  }
}

.productBtn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  cursor: pointer;

  @include Animation(box-shadow);

  &:active {
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
      rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }
}

.quantityValue {
  width: 8px;
}

.linkList {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-secondary);
  font-size: 14px;
}

.details li {
  display: flex;
  line-height: 1.3em;

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  &::before {
    content: '•';
    padding-right: 0.8rem;
  }
}

.details li p {
  margin-top: 6px;
  margin-left: 21px;
}

.details tr:nth-child(1) {
  background-color: var(--color-grey);
}

.details th {
  min-width: 120px;
  padding: 0.8rem 1.6rem;
  font-weight: 500;

  @media screen and (min-width: 1280px) {
    min-width: 200px;
  }
}

.details th:nth-child(1) {
  background-color: var(--color-grey);
}

.details td {
  min-width: 120px;
  width: 100%;
  padding: 0.8rem 1.6rem;
  text-align: center;
}
