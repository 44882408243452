@import 'styles/vars';

.contactForm {
  align-self: center;
}

.formHeading {
  margin-bottom: 40px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--color-grey);

  @media screen and (min-width: 1280px) {
    font-size: 1.4rem;
  }
}

.ctaForm {
  margin-bottom: 2rem;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 2rem;
    text-align: left;
  }

  h2 {
    font-size: 1.5rem;
    width: 100%;
    max-width: 25ch;
    margin: 0 auto;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
      margin: 0;
    }
  }

  p {
    margin-top: 1rem;
    font-size: 1rem;
    width: 100%;
    max-width: 24ch;
    text-align: left;
  }
}

.labelWrap {
  position: relative;
  margin-bottom: 1.2rem;

  @media screen and (min-width: 1280px) {
    margin-bottom: 1.4rem;
  }
}

.formInput {
  width: clamp(120px, 50vw, 420px);
  height: 2.5rem;
  padding: 0.5rem 1.25rem;
  border: 1px solid black;
  border-radius: 2px;
  margin: 0.625rem auto;
  @include Animation(all);

  @media (min-width: 768px) {
    width: clamp(120px, 35vw, 420px);
  }

  &:focus {
    border-top-color: rgba(0, 0, 0, 0.1);
  }
}

.formInput:focus ~ .label,
.formInput:valid ~ .label {
  top: -8px;
  left: 8px;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 0.8em;
  color: var(--color-black);
  cursor: pointer;
  z-index: 2;
  background-color: var(--color-white);

  @media screen and (min-width: 1280px) {
    top: -10px;
    font-size: 1em;
  }
}

.formTextarea {
  composes: formInput;

  resize: none;
  height: 100px;
}

.label {
  position: absolute;
  top: 17px;
  left: 12px;
  font-size: 1rem;
  color: #65564c;
  cursor: text;
  @include Animation(all);

  & + .formInput:placeholder-shown {
    // Styles for label when the input is empty or no value is entered
    top: 17px;
    left: 20px;
    font-size: 1em;
    cursor: text;
  }

  & + .formInput:not(:placeholder-shown) {
    // Styles for label when the input has data
    top: -1.5em;
    left: 0;
    font-size: 0.8em;
    color: inherit;
    cursor: pointer;
  }

  @media screen and (min-width: 1280px) {
    font-size: 1.1rem;
  }
}

.errorMsg {
  position: absolute;
  bottom: -16px;
  right: 0;
  font-size: 12px;
  color: var(--color-black);

  @media screen and (max-width: 1280px) {
    left: 0;
  }
}

.btnWrap {
  text-align: center;
}

.LogoForm {
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 50px;
  margin-bottom: 20px;
}
