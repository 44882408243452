.section {
  padding-top: 40px;
  padding-bottom: 40px;

  @media screen and (min-width: 1280px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.products {
  composes: section;
  padding-bottom: 60px;
}