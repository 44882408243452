@import 'styles/vars.scss';

.btn {
  display: inline-flex;
  align-self: center;
  padding: 0.4em 1em;
  line-height: 35px;
  border: 1px solid;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  // border-color: var(--color-grey);
  border-radius: 4px;
  color: var(--color-primary);
  // text-shadow: 2px 2px 3px rgba(93, 101, 106, 0.3);
  background: #000;
  cursor: pointer;
  transition: 200ms linear;
  box-shadow: 0px 0px 0px 0px #000;
  border: none;
  @include Animation(border-color, text-shadow);

  &:hover {
    // border-color: var(--color-accent);
    background: #ff6801;
    // text-shadow: 2px 2px 3px rgba(56, 76, 88, 0.6);
  }
}

.btnProduct {
  composes: btn;

  align-items: center;
  justify-content: center;
  column-gap: 12px;
  padding: 0.6em 1.8em;
  font-size: 1.4em;

  @media screen and (min-width: 1280px) {
    padding: 0.7em 2.1em;
  }
}

.disabledBtn {
  composes: btn;
  // cursor: default;
  color: #fff;
  // color: var(--color-grey);

  &:hover,
  &:focus {
    border-color: var(--color-grey);
    text-shadow: 2px 2px 3px rgba(93, 101, 106, 0.3);
  }
}
