@import '../../styles/vars.scss';

.card {
  display: flex;
  flex-direction: column;
  @include Animation(box-shadow);

  &:hover,
  &:focus {
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 8%);
    // box-shadow: 0px -12px 13px 0px rgba(0, 0, 0, 0.17) inset,
    //   0px -8px 25px 0px rgba(0, 0, 0, 0.15),
    //   0px -40px 20px 0px rgba(0, 0, 0, 0.1) inset,
    //   0px 2px 1px rgba(0, 0, 0, 0.06), 0px 4px 2px rgba(0, 0, 0, 0.09),
    //   0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09);
  }
}

.cardImage {
  position: relative;
  mix-blend-mode: darken;
}

.badge {
  z-index: 2;
  position: absolute;
  top: 4px;
  left: 28px;
  padding: 2px 4px;
  border-radius: 2px;
  background-color: #2f4859;
  color: #fff;
  text-transform: uppercase;

  @media screen and (min-width: 1280px) {
    font-size: 0.8rem;
  }
}

.desc {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 1rem;
  padding: 20px 24px;
  text-align: center;

  @media screen and (max-width: 719px) {
    align-items: center;
  }
}

.title {
  flex-grow: 1;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  // color: var(--color-primary);
}

.priceList {
  display: flex;
  justify-content: center;
  gap: 18px;
  margin-bottom: 1rem;
}

.price {
  font-size: 1.2rem;
  color: #2f4858;
}

.oldPrice {
  composes: price;

  color: rgb(64, 46, 50, 50%);
  text-decoration: line-through;
}
