@import-normalize;
/* bring in normalize.css styles */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  overflow: overlay;
  color: var(--color-black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--color-grey);
  text-decoration: none;
}

img {
  display: block;
  height: 100%;
  width: 100%;
}

/* Removing input background colour for Chrome autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* clears the ‘X’ in input from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ in input from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.MuiPagination-root {
  button {
    color: #000 !important;
    border: none !important;
    background-color: #ff8735 !important;
  }
}
