.content {
  padding-left: 12px;
  text-align: justify;
  font-size: 16px;
  line-height: 1.3em;

  @media screen and (min-width: 1280px) {
    padding: 48px;

    font-size: 20px;
  }
}

.title {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 1.8rem;

  @media screen and (min-width: 1280px) {
    margin-bottom: 28px;
    font-size: 2rem;
  }
}

.content h4 {
  margin-bottom: 4px;
  text-align: center;
  font-weight: 300;
  font-size: 24px;
}

.content h3,
.content p span {
  position: relative;
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 1.3rem;
  color: var(--color-secondary);

  @media screen and (min-width: 1280px) {
    font-size: 1.4rem;
  }
}

.content p,
.content li {
  margin-bottom: 8px;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.03rem;

  @media screen and (min-width: 1280px) {
    font-size: 1.4rem;
  }
}

.content li {
  line-height: 1.3em;

  @media screen and (min-width: 1280px) {
    line-height: 1.4em;
  }
}

.content li::marker {
  color: var(--color-secondary);
}

.content ol span,
.content ul span {
  font-weight: 300;
  font-size: 24px;
}

.content ul>li::before {
  content: "•";
  padding-right: 0.8rem;
}