@import '../../styles/vars.scss';

.sectionTitle {
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2rem;

  @media screen and (min-width: 1280px) {
    margin-bottom: 40px;
  }
}

.contentWrap {
  @media screen and (min-width: 1280px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
  }
}

.imageWrap {
  width: 300px;

  @media screen and (max-width: 1279px) {
    width: fit-content;
    margin: 0 auto;
  }
}

.imageWrap img {
  object-fit: cover;
}

.textWrap {
  @media screen and (max-width: 1279px) {
    margin-top: 20px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 50%;
    display: flex;
    flex-direction: column;
  }
}

.textWrap > p {
  text-align: justify;
  line-height: 1.4em;
  letter-spacing: 0.06em;
  hyphens: none;

  &:not(:first-child) {
    margin-top: 12px;

    @media screen and (min-width: 1280px) {
      margin-top: 24px;
    }
  }

  @media screen and (min-width: 720px) {
    font-size: 1.1rem;
  }

  @media screen and (min-width: 1280px) {
    font-size: 1.4rem;
  }
}
