@import 'styles/vars';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  z-index: 1;
  position: relative;
  // color: #000;
}

.searchSuggest {
  z-index: 90;
  position: absolute;
  left: 0;
  width: 100%;
  height: fit-content;
  max-height: 80vh;
  border-radius: 0 0 3px 3px;
  color: #000;
  background-color: #ff6801;
  overflow: scroll;

  @media screen and (min-width: 720px) {
    top: 120%;
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  padding-left: 12px;
  font-size: 0.8rem;
  line-height: 1.6rem;
  cursor: 'pointer';

  @media screen and (min-width: 720px) {
    font-size: 1rem;
  }
}

.item a {
  font-weight: 400;
  color: var(--color-primary);
  @include Animation(color);

  &:hover,
  &:focus {
    color: var(--color-white);
  }

  &::before {
    content: '-';
    padding-right: 0.4rem;
  }
}
