@import 'styles/vars';

.beInTouch {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 719px) {
    padding: 0 16px 16px;
  }
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.desc {
  margin-top: 15px;
  color: #ff6701;
  font-size: 14px;

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1279px) {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
  }
}

.title {
  font-size: 16px;
  color: var(--color-grey);

  @media screen and (min-width: 720px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 20px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  flex: 0 1 300px;
  margin: 0 auto;
}

.input {
  width: 100%;
  padding: 0.7rem 1rem;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: var(--color-white);
  font-size: 14px;
}

.button {
  padding: 0.8rem 1rem;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  background-color: var(--color-grey);
  white-space: nowrap;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  @include Animation(box-shadow);

  &:hover,
  &:focus {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.18),
      0 2px 6px 0 rgba(0, 0, 0, 0.15);
  }
}

@container (width >=280px) {
  .form {
    flex-direction: row;
  }

  .input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
