@import 'styles/vars';

.backDrop {
  z-index: 1;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.cart {
  position: absolute;
  right: 0;
  max-width: 600px;
  padding: 40px 20px;

  background-color: var(--color-white);
  box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
}

.wrap {
  overflow: scroll;
  width: 100%;
  height: 84vh;
  padding: 0 12px;
}

.title {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 24px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 32px;

  @media screen and (min-width: 1280px) {
    gap: 42px;
  }
}

.item img {
  width: 80px;
}

.itemTitle {
  margin-bottom: 4px;
  font-size: 18px;
  color: var(--color-black);
}

.itemDesc {
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--color-grey);
}

// .itemPrice {
//   color: var(--color-primary);
// }

.delete {
  color: var(--color-accent-variant);
  font-size: 30px;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--color-grey);
  }
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--color-black);
}

.totalValue {
  font-size: 20px;
  // color: var(--color-primary);
}

.btnWrap {
  margin-bottom: 20px;
}

.reset {
  padding: 4px 8px;
  border-radius: 2px;
  text-transform: lowercase;
  text-align: center;
  // color: var(--color-grey);
  color: #000;
  font-size: 16px;
  box-shadow: -3px 4px 5px rgba(81, 41, 10, 0.1),
    0px 2px 2px rgba(81, 41, 10, 0.2);
  cursor: pointer;

  @include Animation(box-shadow, color);

  &:hover,
  &:focus {
    background-color: var(--color-white);
    box-shadow: -3px 4px 5px rgba(81, 41, 10, 0.2),
      0px 2px 2px rgba(81, 41, 10, 0.4);
    color: red;
  }

  @media screen and (min-width: 1280px) {
    font-size: 18px;
  }
}
