@import 'styles/vars';

.footer {
  padding: 20px 8px;
  background-color: #23313b;
  box-shadow: 0 15px 10px -20px rgba(0, 0, 0, 0.45) inset;

  @media screen and (min-width: 720px) {
    padding: 40px 20px;
  }

  @media screen and (min-width: 1280px) {
    padding: 60px 100px 20px 100px;
  }
}

.top {
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 8px;
  margin: 0 auto;
}

.beintouch {
  container-type: inline-size;
  width: 100%;

  @media screen and (min-width: 720px) {
    flex: 2;
  }
}

.logo {
  display: flex;
}

.logo svg {
  width: 50px;
  height: auto;
  @include Animation(filter);

  // &:hover,
  // &:focus {
  //   filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
  // }

  // &:active {
  //   filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));
  // }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  text-align: justify;
  font-size: 12px;

  @media screen and (min-width: 720px) {
    flex: 1;
    font-size: 14px;
  }
}

.link {
  color: var(--color-secondary);
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  @include Animation(text-decoration);

  &:hover,
  &:focus {
    color: #ff6701;
  }

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
}

.title {
  font-size: 16px;
  text-align: center;
  color: var(--color-grey);

  @media screen and (min-width: 720px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 20px;
  }
}

.item span {
  color: var(--color-grey);
}

.orders {
  @media screen and (min-width: 1280px) {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    text-align: justify;
    font-size: 14px;
  }
}

.text {
  text-align: center;
  letter-spacing: 0.02em;
}

.bottom {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  margin-top: 20px;

  @media screen and (min-width: 1280px) {
    justify-content: center;
    flex-direction: row;
    // align-items: flex-end;
    margin-top: 45px;
    gap: 60px;
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;

  @media screen and (max-width: 1279px) {
    margin-top: 12px;
  }

  @media screen and (min-width: 1280px) {
    position: relative;
    margin-left: 230px;
  }
}

.copyright {
  position: relative;
  bottom: -5px;
  font-size: 12px;
  color: var(--color-grey);
  text-align: center;

  @media screen and (min-width: 1280px) {
    font-size: 14px;
  }
}

.iconList {
  display: flex;
  gap: 28px;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1280px) {
    gap: 20px;
  }
}

.icon {
  color: var(--color-secondary);
  cursor: pointer;
  @include Animation(color, transform);

  &:hover,
  &:focus {
    color: #ff6701;
  }
}
